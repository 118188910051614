<div class="container mr-top-30 mt-5">
  <div class="row justify-content-center">
    <div class="col-12 col-md-8 col-lg-6 pb-5">
      <div class="card rounded-0">
        <div class="card-header p-0">
          <div class="bg-info text-white text-center py-2">
            <h3> Thông báo </h3>
          </div>
        </div>
        <div class="card-body p-3">
          <div class="font-weight-bold text-center">
            Cảm ơn Quý Đối Tác Đã Đăng Kí Thông Tin.
          </div>
          <div class="mt-3">
            Fundiin đã tiếp nhận yêu cầu của Quý Đối Tác và sẽ liên hệ trong thời gian sớm nhất để hoàn tất thủ tục.
          </div>
          <div class="small text-right pt-3"><i>Fundiin</i></div>
        </div>
      </div>
    </div>
  </div>