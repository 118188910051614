import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MerchantRegistry } from '../entities/merchant-registry';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ResponseEntity } from '../entities/response-entity';
import { map } from "rxjs/operators";
import { STATUS } from '../entities/status';

@Injectable({
  providedIn: 'root'
})
export class RegistryService {

  constructor(private http: HttpClient) { }

  registrySapoMerchant(bodyData: MerchantRegistry) {
    
    let url = environment.apiBaseUrl + "/fundiin/partner/register-online-shop";
    let options = {
      headers: {
        "Content-Type": "application/json",
        "privateCode": "2?AHyA9NZPEGBE$jN&lKLVWwT%$H?^kB#oYV6IaujK%JQlNBQL"
      }
    }

    return new Promise<ResponseEntity>((resolved, reject) => {
      return this.http.post<ResponseEntity>(url, JSON.stringify(bodyData), options)
      .pipe( map (res => res as ResponseEntity))
      .subscribe(
        (responseEntity: ResponseEntity) => resolved(responseEntity),
        (errorResponse: HttpErrorResponse) => {
          reject(new ResponseEntity(errorResponse.error.code, STATUS.DEFAULT_MESSAGE));
        });
    });
  }
}
