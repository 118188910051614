<div class="container mr-top-30 mt-5">
  <div class="row justify-content-center">
    <div class="col-12 col-md-8 col-lg-6 pb-5">
      <div class="card rounded-0">
        <div class="card-header p-0">
          <div class="bg-info text-white text-center py-2">
            <h3> Thông báo </h3>
          </div>
        </div>
        <div class="card-body p-3">
          <div class="mt-3">
            Bạn không thể truy cập vào trang đăng ký merchant của chúng tôi
          </div>
          <div class="small text-right pt-3"><i>Fundiin</i></div>
        </div>
      </div>
    </div>
  </div>