<!--UI register: nhap password-->
<div class="container mr-top-30 mt-5">
  <div class="row justify-content-center">
    <div class="col-12 col-md-8 col-lg-6 pb-5">
      <div class="card rounded-0">
        <div class="card-header p-0">
          <div class="bg-info text-white text-center py-2">
            <h3><i class="fa fa-user"></i> Đăng Ký</h3>
          </div>
        </div>
        <div class="card-body p-3">
          <form [formGroup]="sapoIntergrationForm" #form (submit)="registeMerchant(form)" method="POST">
            <!-- <div class="form-group">
              <label>Shop Id <i class="text-danger">( * )</i></label>
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text"><i class="fa fa-lock text-info"></i></div>
                </div>
                <input type="text" class="form-control" formControlName="shopId" required readonly/>
              </div>
            </div> -->
            <div class="form-group">
              <label>Shop Name <i class="text-danger">( * )</i></label>
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text"><i class="fa fa-lock text-info"></i></div>
                </div>
                <input type="text" class="form-control" formControlName="shopName" required readonly/>
              </div>
            </div>
            <div class="form-group">
              <label>Shop Url <i class="text-danger">( * )</i></label>
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text"><i class="fa fa-lock text-info"></i></div>
                </div>
                <input type="text" class="form-control" placeholder="Nhập shop url" formControlName="shopUrl" required readonly/>
              </div>
            </div>
            <div class="form-group">
              <label>Phone Number <i class="text-danger">( * )</i></label>
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text"><i class="fa fa-lock text-info"></i></div>
                </div>
                <input type="text" class="form-control" placeholder="Nhập số điện thoại" formControlName="phoneNumber" required pattern="\d{8,13}"/>
              </div>
            </div>

            <div class="form-group">
              <label>Note </label>
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text"><i class="fa fa-lock text-info"></i></div>
                </div>
                <input type="text" class="form-control" placeholder="Nhập note" formControlName="note" />
              </div>
            </div>
            <div class="text-center">
              <button class="btn btn-info btn-block py-2" type="submit">
                <i class="fa fa-user-plus"></i> Đăng Ký
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>