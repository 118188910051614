import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ResponseEntity } from '../entities/response-entity';
import { RegistryService } from '../services/registry.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-sapo',
  templateUrl: './sapo.component.html',
  styleUrls: ['./sapo.component.css']
})
export class SapoComponent implements OnInit {

  sapoIntergrationForm = new FormGroup({
    shopId: new FormControl(''),
    shopType: new FormControl('SAPOVN'),
    shopName: new FormControl(''),
    shopUrl: new FormControl(''),
    phoneNumber: new FormControl(''),
    orderDetailUrl: new FormControl(''),
    updatePaymentUrl: new FormControl(''),
    updateTagsUrl: new FormControl(''),
    note: new FormControl(''),
  });

  @BlockUI() 
  locker: NgBlockUI;

  constructor(
    private registry: RegistryService, 
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private route: Router,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if ( !params.shop_id || !params.shop_name || !params.cb_detail || !params.cb_payment || !params.cb_tags || !params.shop_api_url) {
        this.route.navigate(['sorry']);
      } 
      this.form.controls['shopId'].setValue(params.shop_id);
      this.form.controls['shopName'].setValue(params.shop_name);
      this.form.controls['orderDetailUrl'].setValue(params.cb_detail);
      this.form.controls['updatePaymentUrl'].setValue(params.cb_payment);
      this.form.controls['updateTagsUrl'].setValue(params.cb_tags);
      this.form.controls['shopUrl'].setValue(params.shop_api_url);
    });
  }

  registeMerchant(form: HTMLFormElement) {

    this.lockTheScreen();

    if (form.checkValidity()) {
      const {shopId, shopName, shopType, shopUrl, phoneNumber, orderDetailUrl, updatePaymentUrl, updateTagsUrl, note} = this.sapoIntergrationForm.value;

      let body = {
        shop_id: shopId,
        shop_name: shopName,
        shop_type: shopType,
        api_base_url: shopUrl,
        phone_number: phoneNumber,
        order_detail_url: orderDetailUrl,
        update_payment_url: updatePaymentUrl,
        update_tags_url: updateTagsUrl,
        shop_note: note
      }

      this.registry.registrySapoMerchant(body)
      .then((res: ResponseEntity) => {
        this.unlockTheScreen();
        this.toastrService.success("Đăng ký thành công!", "Thông báo");
        this.route.navigate(['/thankyou']);
      })
      .catch((error: ResponseEntity) => {
        this.unlockTheScreen();
        this.toastrService.error("Đăng ký thất bại. Vui lòng thử lại", "Thông báo");
      });
    }
    Array.from(form.elements).forEach((element) => {
      let elementInHtml = element as HTMLInputElement;
      if (! elementInHtml.checkValidity() ) {
        let name = elementInHtml.getAttribute("formcontrolname");
        this.toastrService.error(`${name} đang bỏ trống hoặc không hợp lệ!`, "Thông báo");
      }
    });
    this.unlockTheScreen();
    return false;
  }
  get form(): FormGroup {
    return this.sapoIntergrationForm;
  }

  lockTheScreen() {
    if (!this.locker.isActive) {
      this.locker.start();
    }
  }
  unlockTheScreen() {
    if (this.locker.isActive) {
      this.locker.stop();
    }
  }
}
