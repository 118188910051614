import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { SapoComponent } from './sapo/sapo.component';
import { SorryComponent } from './sorry/sorry.component';
import { ThankYouComponent } from './thank-you/thank-you.component';

const routes: Routes = [
  { path: '', component: SapoComponent },
  { path: 'sapo', component: SapoComponent },
  { path: 'thankyou', component: ThankYouComponent },
  { path: 'sorry', component: SorryComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
